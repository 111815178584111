/* eslint-disable react/button-has-type */
import React from 'react';
import SyncLoader from 'react-spinners/ClipLoader';
import cx from 'classnames';
import styles from './LoadingButton.module.scss';

interface LoadingButtonI {
  disabled?: boolean;
  block?: boolean;
  loading?: boolean;
  text: string;
  type: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: CallableFunction;
}

const LoadingButton = (props: LoadingButtonI): JSX.Element => {
  const { disabled, loading, text, type, className, onClick, block } = props;

  return (
    <button
      className={cx(styles.root, styles.primary, className, {
        [styles.block]: block,
      })}
      type={type}
      disabled={disabled}
      onClick={(): void => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {loading ? <SyncLoader size={20} color="#ffffff" loading={loading} /> : text}
    </button>
  );
};

export default LoadingButton;
