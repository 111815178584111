import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { I18n } from 'core';

import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import autoInformeService from 'services/remote/AutoInforme';
import { AutoInforme } from 'types/models/AutoInforme';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import ModalMatcher from 'components/ModalMatcher/ModalMatcher';
import { CommunitiesAutoreportListTable } from './components/communities-autoreport-list-table/communities-autoreport-list-table';
import { CommunitiesAutoreportListFilters } from './components/communities-autoreport-list-filters/communities-autoreport-list-filters';
// import { CommunitiesEnrolledListPaginator } from './components/communities-enrolled-list-paginator/communities-enrolled-list-paginator';

import styles from './comunities-autoreport-list-page.module.scss';

interface ParameterDetalles {
  communityID: string;
}

export const ComunitiesAutoreportListPage = (): JSX.Element => {
  const [communitiesEnrolled, setCommunitiesEnrolled] = useState<AutoInforme[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [showModalDeleteAutoInforme, setShowModalDeleteAutoInforme] = useState<boolean>(false);
  const [statusSolicitud, setStastusSolicitud] = useState<string>('');
  const { communityID } = useParams<ParameterDetalles>();
  const [filters, setFilters] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>(''); // Nuevo estado para el mensaje de error

  const handleFilterChange = (value: any) => {
    setFilters(value);
  };

  const fetchData = async () => {
    try {
      setLoadingData(true); // Activa el loader antes de iniciar la carga de datos

      if (communityID !== undefined) {
        // Primera solicitud: getAutoInforme
        await autoInformeService.getAutoInforme(communityID).then((response) => {
          setCommunitiesEnrolled(response);
        });

        // Segunda solicitud: getAutoInformeSolicitud
        await autoInformeService
          .getAutoInformeSolicitud(communityID)
          .then((response) => {
            setStastusSolicitud(response);
          })
          .catch(() => {
            setStastusSolicitud('NO_EXISTE');
          });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingData(false); // Desactiva el loader cuando ambas solicitudes hayan finalizado
    }
  };
  useEffect(() => {
    fetchData();
  }, [filters]);

  const createAutoInformeSolicitud = async () => {
    try {
      const response = await autoInformeService.createAutoInformeSolicitud(communityID);
      if (response === 200) {
        autoInformeService
          .getAutoInformeSolicitud(communityID)
          .then((response2) => {
            setStastusSolicitud(response2);
          })
          .finally(() => {
            setErrorMessage('');
          });
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        const response = error.response.data; // Captura el texto plano del error devuelto por el servidor
        setErrorMessage(response); // Actualiza el estado con el mensaje de error

        // Mostrar mensaje de error al usuario si es necesario
      } else {
        setErrorMessage('Ocurrió un error inesperado.'); // Mensaje genérico para otros errores
      }
    }
  };

  const deleteAutoInformeSolicitud = async () => {
    try {
      // Realiza la solicitud para eliminar el autoinforme
      const response = await autoInformeService.deleteAutoInformeSolicitud(communityID);

      if (response === 200) {
        // Si la eliminación es exitosa, crea un nuevo autoinforme

        await createAutoInformeSolicitud();
        fetchData(); // Actualiza la lista de autoinformes
        setShowModalDeleteAutoInforme(false); // Cierra el modal
      } else {
        setErrorMessage('No se pudo eliminar el autoinforme.');
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al eliminar el autoinforme.');
    }
  };

  const escapeCsvValue = (value: string | number) => {
    if (value === undefined || value === null) {
      return '-'; // Valor por defecto si es undefined o null
    }
    const stringValue = String(value);
    // Escapar comillas dobles y envolver el valor entre comillas si contiene comas o comillas
    if (stringValue.includes(',') || stringValue.includes('"')) {
      return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
  };
  const formatFloatValue = (value: string | number) => value.toString().replace('.', ','); // Reemplaza '.' con ',' para el punto flotante
  const exportAllToCSV = () => {
    const headers = [
      'Nombre',
      'CUPS',
      'DNI (NIE)',
      'Comunidad',
      'Origen de datos',
      'Tipo de consumo',

      'P1',
      'P2',
      'P3',
      'P4',
      'P5',
      'P6',
      'P.EX',
      'Potencia kWn',
      'Excedente',
      'kwh Autoconsumo',
      'Consumo Anual',
      '% Autoconsumo',
      'Ahorro',
    ];

    const csvContent = [
      headers.join(';'), // Usa ';' como separador de columnas
      ...communitiesEnrolled.map(
        (rowDetail) =>
          [
            escapeCsvValue(`${rowDetail.nombre} ${rowDetail.apellidos}`),
            escapeCsvValue(rowDetail.cups),
            escapeCsvValue(rowDetail.nif),
            escapeCsvValue(rowDetail.comunidad),
            escapeCsvValue(rowDetail.informeOrigenDeDatos),
            escapeCsvValue(rowDetail.tipoConsumo),

            formatFloatValue(escapeCsvValue(rowDetail.terminoEnergiaP1)),
            formatFloatValue(escapeCsvValue(rowDetail.terminoEnergiaP2)),
            formatFloatValue(escapeCsvValue(rowDetail.terminoEnergiaP3)),
            formatFloatValue(escapeCsvValue(rowDetail.terminoEnergiaP4)),
            formatFloatValue(escapeCsvValue(rowDetail.terminoEnergiaP5)),
            formatFloatValue(escapeCsvValue(rowDetail.terminoEnergiaP6)),
            formatFloatValue(escapeCsvValue(rowDetail.precioCompensacion)),
            rowDetail.potencia !== '-' ? formatFloatValue(escapeCsvValue(`${rowDetail.potencia}`)) : '-',
            rowDetail.informeExcedente !== '-'
              ? formatFloatValue(escapeCsvValue(`${rowDetail.informeExcedente}`))
              : '-',
            rowDetail.informeAutoconsumo && rowDetail.informeAutoconsumo !== '-'
              ? formatFloatValue(escapeCsvValue(`${rowDetail.informeAutoconsumo}`))
              : '-',
            rowDetail.informeConsumoAnual !== '-'
              ? formatFloatValue(escapeCsvValue(`${rowDetail.informeConsumoAnual}`))
              : '-',
            rowDetail.informePorcentajeDeAutoconsumo && rowDetail.informePorcentajeDeAutoconsumo !== '-'
              ? formatFloatValue(
                  escapeCsvValue(`${Math.round(Number(rowDetail.informePorcentajeDeAutoconsumo) * 100)}`),
                )
              : '-',
            rowDetail.informeAhorro && rowDetail.informeAhorro !== '-'
              ? formatFloatValue(escapeCsvValue(`${parseFloat(rowDetail.informeAhorro).toFixed(2)}`))
              : '-',
          ].join(';'), // Usa ';' como separador de columnas
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const now = new Date();
    const currentDate = now.toISOString().split('T')[0];
    const currentTime = now.toTimeString().split(' ')[0].replace(/:/g, '-');

    const fileName = `solicitud-${currentDate}-${currentTime}.csv`;

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={communityID !== undefined ? styles.container : styles.container2}>
      {loadingData ? (
        <div className={styles.fullPageLoader}>
          <FullPageSyncLoader icon />
        </div>
      ) : (
        <>
          {communityID !== undefined ? null : (
            <div className={styles.header}>
              <Button
                className={styles.viewmore_button}
                variant="tertiary"
                type="button"
                onClick={(): Promise<void> => navigate('/tecnic/dashboard')}
                text={I18n.t('UsersPage.goDashboard')}
              />
              {}
            </div>
          )}
          <SectionWrapper>
            <CommunitiesAutoreportListFilters
              handleChange={handleFilterChange}
              statusSolicitud={statusSolicitud}
              createAutoInformeSolicitud={createAutoInformeSolicitud}
              deleteAutoInformeSolicitud={() => setShowModalDeleteAutoInforme(true)}
            />

            <div className={`${styles.centerButton} ${errorMessage ? styles.spaceBetween : styles.end}`}>
              {' '}
              {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
              <Button
                className={styles.viewmore_button}
                variant="tertiary"
                type="button"
                onClick={exportAllToCSV}
                text="export CSV"
              />
            </div>
            <div className={styles.table_wrapper}>
              <CommunitiesAutoreportListTable data={communitiesEnrolled} communityID={communityID} />
            </div>
            <div>
              {/* <CommunitiesEnrolledListPaginator current={0} max={1} handleNext={() => {}} handlePrev={() => {}} /> */}
            </div>
            {showModalDeleteAutoInforme && (
              <ModalMatcher
                title={I18n.t('ComunitiesAutoReportListPage.modalDeleteTitle')}
                body={I18n.t('ComunitiesAutoReportListPage.modalDeleteBody')}
                onClick={() => deleteAutoInformeSolicitud()}
                testbutton={I18n.t('Confirmar')}
                onClick2={() => setShowModalDeleteAutoInforme(false)}
              />
            )}
          </SectionWrapper>
        </>
      )}
    </div>
  );
};
