/* eslint-disable @typescript-eslint/no-unused-vars */
import { SectionSubTitle, SectionTitle, TabsSwitch } from 'components';
import Button from 'components/Button/Button';
import { ElecsumCheckbox, RoundedButton } from 'components/NewInputs';
import React, { useState } from 'react';

import { ReactComponent as MembersIcon } from 'assets/icons/community_share.svg';
import styles from './TestPage.module.scss';

const TestPage = (): JSX.Element => (
  <>
    <MembersIcon height={150} width={150} fill="red" color="blue" />
  </>
);

export default TestPage;
