import React, { useState } from 'react';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';

import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';
import { Colors } from 'styles';
import { LoadingButton, SectionSubTitle } from 'components';
import { FormGroup, FormGroupProps, I18n } from 'core';
import CupsTooltipOferta from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipOferta';
import ButtonCsv from 'components/ButtonCsv/ButtonCsv';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import { LoggingService } from 'services';

import customToast from 'components/CustomToast/CustomToast';
import { CommunityModel } from 'types/models/community';
import styles from './comunidades-detalle-csv.module.scss';

interface ENPropsI {
  communityId: string;
  currentLang: SelectItemI;
  hasCurva: string;
  title: string;
  textButton: string;
  afterSubmitAction?: CallableFunction;
  textButtonDownload: string;
  textTooltip: string;
  typeCurva: string;
  selectedCommunity: CommunityModel;
}
const ComunidadesDetalleCsv = ({
  communityId,
  afterSubmitAction,
  hasCurva,
  title,
  textButton,
  textButtonDownload,
  textTooltip,
  typeCurva,
  selectedCommunity,
}: ENPropsI): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [consumoAnualPromedio, setConsumoAnualPromedio] = useState<number>(
    selectedCommunity?.consumoAnualPromedio || 0,
  );

  const [loadingExportInforme, setLoadingExportInforme] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errors, setErrors] = useState({});
  const [mensajeError, setMensajeError] = useState({
    codigo: '',
    mensaje: '',
  });
  const [mensajeErrorActualCsv, setMensajeErrorActualCsv] = useState('');
  const validateForm = (): boolean => {
    if (!selectedFile) {
      setSubmitDisabled(true);
      setErrors({});
      return false;
    }
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };

  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (selectedFile) {
        const patchCommunityCurvaCsv =
          typeCurva === 'curva-generacion'
            ? await CommunitiesServiceIntance.patchCsvConfiguracionComunidades(communityId, selectedFile)
            : await CommunitiesServiceIntance.patchCsvCurvaDeConsumo(communityId, selectedFile);

        if (patchCommunityCurvaCsv === 200) {
          const mensaje =
            typeCurva === 'curva-generacion'
              ? `${I18n.t('ComunidadesDetallePage.detailData.curva.subidaCorrectamente')}!`
              : `${I18n.t('ComunidadesDetallePage.detailData.curva.subidaCorrectamenteConsumo')}!`;
          customToast.success(mensaje);

          if (afterSubmitAction) {
            afterSubmitAction();
          }

          setSubmitDisabled(false);
        } else {
          setLoading(false);
          setMensajeError(patchCommunityCurvaCsv);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    setSelectedFile(null);

    return true;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { selectedFile });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { selectedFile, errors });
    }
  };

  const exportInforme = async (): Promise<void> => {
    setLoadingExportInforme(true);

    try {
      const ejecutarInforme =
        typeCurva === 'curva-generacion'
          ? await CommunitiesServiceIntance.descargarInformeComunidades(communityId)
          : await CommunitiesServiceIntance.descargarInformeConsumoComunidades(communityId);

      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${ejecutarInforme}`;
      a.textContent = 'download';
      a.download = `${new Date().toLocaleDateString()}_ExportData.csv`;
      a.click();
      a.remove();
    } catch (error: any) {
      setMensajeErrorActualCsv(error.response.data);
    } finally {
      setLoadingExportInforme(false);
    }
  };
  const formFieldsRight: Array<FormGroupProps> = [
    {
      label: `${I18n.t('ComunidadesDetallePage.detailData.labels.consumoAnualPromedio')} kWh`,
      type: 'number',
      id: 'consumoAnualPromedio',
      path: 'consumoAnualPromedio',
      name: 'consumoAnualPromedio',
      placeholder: '',
      value: consumoAnualPromedio,
    },
  ];

  function handleChange(path: string, value: any): void {
    selectedCommunity.setAttr(path, value);
    setConsumoAnualPromedio(value);
  }
  return (
    <SectionWrapper customClassname={styles.root}>
      <div className={styles.flex}>
        <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />

        <SectionSubTitle text={title} />
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        {hasCurva === 'si' ? (
          <div className={styles.datosPerfilConsumo}>
            <p>{I18n.t('ComunidadesDetallePage.detailData.curva.yaHayCsv')}</p>
          </div>
        ) : (
          ''
        )}
        {typeCurva === 'curva-generacion'
          ? null
          : formFieldsRight.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
                onChange={handleChange}
                maxLenght={200}
              />
            ))}

        <div className={styles.modalCsv}>
          <CupsTooltipOferta title={textTooltip} />

          <ButtonCsv className={styles.csv_button} onFileSelect={setSelectedFile} />
          {mensajeError.codigo === '' ? null : (
            <div className={styles.errorMessage}>
              <p>{I18n.t(mensajeError.codigo)}</p>
              <p>{mensajeError.mensaje}</p>
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <LoadingButton type="submit" text={textButton} disabled={submitDisabled} loading={loading} />
        </div>
        {hasCurva === 'si' ? (
          <>
            <div className={styles.actions}>
              <LoadingButton
                type="submit"
                text={textButtonDownload}
                onClick={exportInforme}
                loading={loadingExportInforme}
                className={styles.loadingButtonSameSize}
              />
            </div>
            <div className={styles.actions}>
              {mensajeErrorActualCsv === '' ? null : (
                <div className={styles.errorMessage}>
                  <p>{mensajeErrorActualCsv}</p>
                </div>
              )}
            </div>
          </>
        ) : null}
      </form>
    </SectionWrapper>
  );
};

export default ComunidadesDetalleCsv;
